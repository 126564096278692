import React, { useState, useEffect } from "react";
import styled from "styled-components";
import aws from "../util/aws";
import { arrayBufferToBlob } from "blob-util";
import pdf from "../util/pdf";
import colors from "../util/colors";
import agreements from "../util/agreements";
import applications from "../util/application";
import adBlocker from "just-detect-adblock";
import templates from "../util/templates";

const Message = styled.div`
  width: 100%;
  padding: 16px 32px;
  position: fixed;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  bottom: ${(props) => (props.show ? "0px" : "-100px")};
  left: 0;
  background: ${colors.blue};
  color: white;
  font-weight: 700;
  z-index: 11;
  text-align: center;
`;

export default (props) => {
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);

  const generatePrintDocument = async (agreement) => {
    const response = agreement.agreement_id
      ? await agreements.get(agreement.agreement_id)
      : await templates.get(agreement.template_id);
    const html = await applications.generateHtml(response.data);
    const generated = await pdf.generate(html, null, "pdf");
    const file_name = generated.data.Key;

    agreement.agreement_filename = file_name;

    const latestApplication = await applications.get(props.application.id);
    for (var j in latestApplication.data.agreements.resident) {
      if (
        latestApplication.data.agreements.resident[j].template_id ===
        agreement.template_id
      ) {
        latestApplication.data.agreements.resident[j] = agreement;
      }
    }
    await applications.update(latestApplication.data);

    const buffer = await aws.getObject(agreement.agreement_filename);
    return arrayBufferToBlob(buffer.data, "application/pdf");
  };

  useEffect(() => {
    async function print() {
      if (adBlocker.isDetected()) {
        alert("Disable adblocker on this domain to allow print tab to open");
      } else {
        try {
          setShowMessage(true);
          setMessage("Preparing Document");

          let url;
          if (props.agreement.agreement_filename) {
            const buffer = await aws.getObject(
              props.agreement.agreement_filename
            );
            const blob = arrayBufferToBlob(buffer.data, "application/pdf");
            url = window.URL.createObjectURL(blob);
            console.log("print: IF");
          } else {
            console.log("print: ELSE");
            // if (props.agreement.agreement_id) {
            //   const agreement = await agreements.get(
            //     props.agreement.agreement_id
            //   );
            //   console.log(agreement);
            //   const html = applications.generateHtml(agreement.data);
            //   const generated = await pdf.generate(
            //     html,
            //     agreement.data.details.size,
            //     "pdf"
            //   );
            //   const buffer = await aws.getObject(generated.data.Key);
            //   const blob = arrayBufferToBlob(buffer.data, "application/pdf");
            //   url = window.URL.createObjectURL(blob);
            // } else {
            //   const template = await templates.get(props.agreement.template_id);
            //   const html = applications.generateHtml({
            //     pages: { pages: template.data.pages.pages },
            //     details: {},
            //   });
            //   const generated = await pdf.generate(
            //     html,
            //     template.data.details.size,
            //     "pdf"
            //   );
            //   const buffer = await aws.getObject(generated.data.Key);
            //   const blob = arrayBufferToBlob(buffer.data, "application/pdf");
            const blob = await generatePrintDocument(props.agreement);
            url = window.URL.createObjectURL(blob);
            // }
          }

          try {
            window.open(url);
          } catch {
            alert(
              "Error opening tab to print document, visit the help center for more information"
            );
          }

          setMessage(undefined);
        } catch (error) {}
      }
    }

    if (props.agreement) print();
  }, [props.agreement]);

  return <Message show={showMessage && message}>{message}</Message>;
};
